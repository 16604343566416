import {buttonVariants} from "@components/ui/button";
import {Link} from "react-router-dom";
import {urls} from "@routes";
import {ArrowLeft} from "lucide-react";

export default function SubscriptionRequestSuccess() {
	return (
		<div className="w-full flex flex-col items-center justify-center">
			<h1 className={"text-4xl font-helvetica-neue-bold"}>
				We Got Your Request!
			</h1>
			<p className={"text-lg mt-2"}>
				We'll get back to you as soon as possible, usually within 12-72 hours.
			</p>
			<Link to={urls["dashboard"]}
						className={buttonVariants({variant: "default", size: "sm", className: "mt-6"})}>
				<ArrowLeft className={"w-5 h-5 mr-2"}/>Dashboard
			</Link>
		</div>
	)
}
