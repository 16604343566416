import {Link, useOutletContext, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageLoading from "@components/ui/pageloading";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {BaseOutletContext} from "@pages/Base";
import {Label} from "@components/ui/label";
import {Input} from "@components/ui/input";
import {Button, buttonVariants} from "@components/ui/button";
import {Blocks, Calendar, ChevronRight, Mail, MessageCircle, SaveIcon, SquareArrowOutUpRight} from "lucide-react";
import {urls} from "@routes";
import {Checkbox} from "@components/ui/checkbox";

interface PageData {
	status_code: number
	status_text: string

	username: string
	user_email: string;
	extra_usage_seconds: number
	block_all_calls: boolean

	// Stats
	verified_numbers: Array<string>
	current_usage_seconds: number
	current_meeting_usage_seconds: number
	remaining_qouta_seconds: number
	remaining_meeting_qouta_seconds: number
	verified_numbers_count: number
	local_numbers_count: number
	local_numbers_max: number
	tollfree_numbers_count: number
	tollfree_numbers_max: number

	// Integrations
	sms_country_code: string
	sms_number: string
	wa_country_code: string
	wa_number: string
	slackbot_webhook: string
	google_auth_active: boolean
}

// interface SMSIntegrationResponse {
// 	status_code: number
// 	status_text: string
//
// 	action: string
// }

export default function AdminUserDetails() {
	const {userID} = useParams();
	const {showToast} = useOutletContext<BaseOutletContext>();

	const [pageData, setPageData] = useState<PageData>();
	const [extraUsageSeconds, setExtraUsageSeconds] = useState<number>(0);
	const [
		extraMeetingUsageSeconds,
		setExtraMeetingUsageSeconds
	] = useState<number>(0);
	const [blockCalls, setBlockCalls] = useState(false);

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["adminUserDetailsPageData"],
		queryFn: () => authenticateAndFetchData("/api/admin/user-details?id=" + userID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData
			setPageData(data);
			setExtraUsageSeconds(data.extra_usage_seconds);
			setBlockCalls(data.block_all_calls);
		}
	}, [pageDataQuery.data]);

	// Mutation to save profile changes.
	const saveChangesMutation = useMutation({
		mutationKey: ["saveUserDetailsChanges"],
		mutationFn: () => authenticateAndPostData("/api/admin/user-details/", {
			"user_id": userID,
			"extra_usage_seconds": extraUsageSeconds,
			"extra_meeting_usage_seconds": extraMeetingUsageSeconds,
			"block_all_calls": blockCalls,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// Show alert and refetch data.
			pageDataQuery.refetch().then();
			showToast(
				"Success!",
				"All changes have been saved successfully.",
				"default"
			);
		},
		onError: (error: ApiRequestFailed) => {
			// Show alert.
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive"
			);
		}
	});

	// // Mutation to save SMS integration.
	// const smsIntegrationMutation = useMutation({
	// 	mutationKey: ["adminSmsIntegration"],
	// 	mutationFn: (data: {
	// 		action: string,
	// 		smsCountryCode: string,
	// 		smsNumber: string,
	// 	}) => authenticateAndPostData("/api/admin/integrations/sms/", {
	// 		"user_id": userID || "",
	// 		"action": data.action,
	// 		"sms_country_code": data.smsCountryCode,
	// 		"sms_number": data.smsNumber,
	// 	}),
	// 	gcTime: 0,
	// 	retry: retryFn,
	// 	onSuccess: (response) => {
	// 		let data = response.data.data as SMSIntegrationResponse;
	// 		// Show alert and refetch page data.
	// 		if (data.action === "add") {
	// 			showToast(
	// 				"Success!",
	// 				`SMS integration has been set up for ${pageData!.user_email}`,
	// 				"default",
	// 			);
	//
	// 		} else {
	// 			showToast(
	// 				"Success!",
	// 				`SMS integration removed for ${pageData!.user_email}`,
	// 				"default",
	// 			);
	// 		}
	// 		pageDataQuery.refetch().then();
	// 	},
	// 	onError: (error: ApiRequestFailed) => {
	// 		// Show alert.
	// 		console.error(error);
	// 		showToast(
	// 			"Error",
	// 			error.data.message,
	// 			"destructive",
	// 		);
	// 	}
	// });

	// function addSmsIntegration(smsCountryCode: string, smsNumber: string) {
	// 	if (smsCountryCode && smsNumber) {
	// 		smsIntegrationMutation.mutate({
	// 			action: "add",
	// 			smsCountryCode: smsCountryCode,
	// 			smsNumber: smsNumber,
	// 		});
	// 	} else {
	// 		showToast(
	// 			"Error",
	// 			"Please enter valid values.",
	// 			"destructive",
	// 		);
	// 	}
	// }

	// function removeSmsIntegration() {
	// 	smsIntegrationMutation.mutate({
	// 		action: "remove",
	// 		smsCountryCode: "",
	// 		smsNumber: "",
	// 	});
	// }

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {
		return (
			<div className="w-full flex flex-col justify-center items-center">
				<h1 className="font-helvetica-neue-bold text-4xl">
					Manage Account <span className="text-primary">{pageData.user_email}</span> ({pageData.username})
				</h1>

				<hr className="w-full my-8"/>

				<div className="w-full grid grid-cols-3 gap-6">
					{/* --------------------- Editable stuff --------------------- */}
					<div className="w-full h-fit flex flex-col border rounded-2xl p-6">
						<h2 className="font-helvetica-neue-bold text-2xl">Edit Account Details:</h2>
						{/* Subscriptions */}
						<Link to={urls["adminUserSubscriptions"].replace(":userID", userID || "")}
									target={"_blank"}
									rel={"noreferrer"}
									className={buttonVariants({variant: "outline", className: "mt-6"})}>
							Manage Subscription<SquareArrowOutUpRight className="w-4 h-4 ml-2"/>
						</Link>

						{/* Extra Phone Usage Seconds */}
						<div className="w-full flex flex-col mt-6">
							<Label htmlFor="extra-usage-seconds">
								Extra Call Usage Seconds:
							</Label>
							<Input type="number"
										 id="extra-usage-seconds"
										 className="mt-4 border"
										 value={extraUsageSeconds}
										 onChange={e => setExtraUsageSeconds(parseInt(e.target.value))}
										 required/>
						</div>
						{/* Extra Meeting Usage Seconds */}
						<div className="w-full flex flex-col mt-6">
							<Label htmlFor="extra-meeting-usage-seconds">
								Extra Meeting Usage Seconds:
							</Label>
							<Input type="number"
										 id="extra-meeting-usage-seconds"
										 className="mt-4 border"
										 value={extraMeetingUsageSeconds}
										 onChange={e => setExtraMeetingUsageSeconds(parseInt(e.target.value))}
										 required/>
						</div>
						{/* Block Calls */}
						<div className="items-top flex space-x-2 mt-6">
							<Checkbox id="block-all-calls" defaultChecked={blockCalls} onClick={() => setBlockCalls(!blockCalls)}/>
							<div className="grid gap-1.5 leading-none">
								<label
									htmlFor="block-all-calls"
									className="text-destructive text-sm font-bold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
								>
									Block All Calls
								</label>
								<p className="text-sm text-muted-foreground">
									Prevents this account from initiating all types of calls except inbound ones.
								</p>
							</div>
						</div>
						<Button className="mt-6" onClick={() => saveChangesMutation.mutate()}>
							<SaveIcon className="w-4 h-4 mr-2"/>Save Changes
						</Button>
					</div>
					{/* --------------------- Links --------------------- */}
					<div className="w-full h-fit grid grid-cols-1 gap-6 border rounded-2xl p-6">
						<h2 className="font-helvetica-neue-bold text-2xl">Call Related Details:</h2>
						<Link to={urls["adminUserCallLogs"].replace(":userID", userID || "")}
									className={buttonVariants({variant: "outline"})}>
							Check Logs<ChevronRight className="w-4 h-4 ml-2"/>
						</Link>
						<Link to={urls["adminUserVerifiedNumbers"].replace(":userID", userID || "")}
									className={buttonVariants({variant: "outline"})}>
							Verified Numbers<ChevronRight className="w-4 h-4 ml-2"/>
						</Link>
						<Link to={urls["adminUserPurchasedNumbers"].replace(":userID", userID || "")}
									className={buttonVariants({variant: "outline"})}>
							Purchased Numbers<ChevronRight className="w-4 h-4 ml-2"/>
						</Link>
					</div>
					{/* --------------------- Integrations --------------------- */}
					<div className="w-full h-fit grid grid-cols-1 gap-6 border rounded-2xl p-6">
						<h2 className="font-helvetica-neue-bold text-2xl">Integrations:</h2>
						<Button variant={"outline"} disabled={true}>
							<Mail className="w-4 h-4 mr-2"/>SMS<ChevronRight className="w-4 h-4 ml-2"/>
						</Button>
						<Button variant={"outline"} disabled={true}>
							<MessageCircle className="w-4 h-4 mr-2"/>Whatsapp<ChevronRight className="w-4 h-4 ml-2"/>
						</Button>
						<Button variant={"outline"} disabled={true}>
							<Calendar className="w-4 h-4 mr-2"/>Google Calendar<ChevronRight className="w-4 h-4 ml-2"/>
						</Button>
						<Button variant={"outline"} disabled={true}>
							<Blocks className="w-4 h-4 mr-2"/>Slack<ChevronRight className="w-4 h-4 ml-2"/>
						</Button>
					</div>
				</div>

				<hr className={"w-full my-8"}/>

				<UsageDetails
					totalPhoneUsedSeconds={pageData.current_usage_seconds}
					totalPhoneRemainingSeconds={pageData.remaining_qouta_seconds}
					totalMeetingUsedSeconds={pageData.current_meeting_usage_seconds}
					totalMeetingRemainingSeconds={pageData.remaining_meeting_qouta_seconds}
					verifiedNumbersCount={pageData.verified_numbers.length}
					localNumbersCount={pageData.local_numbers_count}
					localNumbersMax={pageData.local_numbers_max}
					tollfree_numbers_max={pageData.tollfree_numbers_max}
					tollfreeNumbersCount={pageData.tollfree_numbers_count}/>

			</div>
		)

	} else {
		// Ideally it should not reach here.
		return <></>
	}
}

function UsageDetails(props: {
	totalPhoneUsedSeconds: number,
	totalPhoneRemainingSeconds: number,
	totalMeetingUsedSeconds: number,
	totalMeetingRemainingSeconds: number,
	verifiedNumbersCount: number,
	localNumbersCount: number,
	localNumbersMax: number,
	tollfreeNumbersCount: number,
	tollfree_numbers_max: number,
}) {
	let phoneUsedMinutes: number = Math.floor(props.totalPhoneUsedSeconds / 60)
	let phoneUsedSeconds: number = props.totalPhoneUsedSeconds % 60
	let phoneRemainingMinutes: number = Math.floor(props.totalPhoneRemainingSeconds / 60)
	let phoneRemainingSeconds: number = props.totalPhoneRemainingSeconds % 60

	let meetingUsedMinutes: number = Math.floor(props.totalMeetingUsedSeconds / 60)
	let meetingUsedSeconds: number = props.totalMeetingUsedSeconds % 60
	let meetingRemainingMinutes: number = Math.floor(props.totalMeetingRemainingSeconds / 60)
	let meetingRemainingSeconds: number = props.totalMeetingRemainingSeconds % 60

	return (
		<div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
			<div className="border rounded-2xl p-6">
				<p className="font-bold text-muted-foreground">Phone Call Time Used:</p>
				<p className="mt-2 text-2xl">{phoneUsedMinutes} min {phoneUsedSeconds} sec</p>
			</div>
			<div className="border rounded-2xl p-6">
				<p className="font-bold text-muted-foreground">Phone Call Time Remaining:</p>
				<p className="mt-2 text-2xl">{phoneRemainingMinutes} min {phoneRemainingSeconds} sec</p>
			</div>
			<div className="border rounded-2xl p-6">
				<p className="font-bold text-muted-foreground">Meeting Call Time Used:</p>
				<p className="mt-2 text-2xl">{meetingUsedMinutes} min {meetingUsedSeconds} sec</p>
			</div>
			<div className="border rounded-2xl p-6">
				<p className="font-bold text-muted-foreground">Meeting Call Time Remaining:</p>
				<p className="mt-2 text-2xl">{meetingRemainingMinutes} min {meetingRemainingSeconds} sec</p>
			</div>
			<div className="border rounded-2xl p-6">
				<p className="font-bold text-muted-foreground">Numbers Verified:</p>
				<p className="mt-2 text-2xl">{props.verifiedNumbersCount}</p>
			</div>
			<div className="border rounded-2xl p-6">
				<p className="font-bold text-muted-foreground">Local Numbers Purchased:</p>
				<p className="mt-2 text-2xl">{props.localNumbersCount} / {props.localNumbersMax}</p>
			</div>
			<div className="border rounded-2xl p-6">
				<p className="font-bold text-muted-foreground">Toll Free Numbers Purchased:</p>
				<p className="mt-2 text-2xl">{props.tollfreeNumbersCount} / {props.tollfree_numbers_max}</p>
			</div>
		</div>
	)
}
