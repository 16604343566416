import {useEffect, useState} from "react";
import PageLoading from "@components/ui/pageloading";
import {ApiRequestFailed, authenticateAndFetchData, authenticateAndPostData, retryFn} from "@lib/apis";
import PageDataErrorHandler from "@components/data/pageDataErrorHandler";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Card, CardHeader} from "@components/ui/card";
import {RadioGroup, RadioGroupItem} from "@components/ui/radio-group";
import {Label} from "@components/ui/label";
import {
	Select,
	SelectContent,
	SelectGroup,
	SelectItem,
	SelectLabel,
	SelectTrigger,
	SelectValue
} from "@components/ui/select";
import {Button} from "@components/ui/button";
import {CreditCard} from "lucide-react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger
} from "@components/ui/dialog";
import {BaseOutletContext} from "@pages/Base";


interface PageData {
	status_code: number
	status_text: string

	username: string
	user_email: string;

	all_renewal_types: Array<RenewalType>
	all_plans: Array<Plan>

	user_current_plan: CurrentPlan
}

interface RenewalType {
	id: string
	display_name: string
}

interface Plan {
	id: string
	display_name: string
	renewal_type_id: string
}

interface CurrentPlan {
	id: string
	display_name: string
	renewal_type_id: string
	purchased_on: string
	next_renewal_date: string
}

export default function AdminUserSubscription() {
	const {userID} = useParams();
	const [pageData, setPageData] = useState<PageData>();
	// holds renewal type id
	const [renewalType, setRenewalType] = useState("");
	// holds plan backend id
	const [newPlan, setNewPlan] = useState("");

	// Fetch page data.
	const pageDataQuery = useQuery({
		queryKey: ["adminUserDetailsPageData"],
		queryFn: () => authenticateAndFetchData("/api/admin/user-subscription?id=" + userID),
		gcTime: 0,
		retry: retryFn,
		refetchOnWindowFocus: false,
	});
	useEffect(() => {
		if (pageDataQuery.data) {
			let data = pageDataQuery.data.data as PageData
			setPageData(data);
			setRenewalType(data.all_renewal_types[0].id);
		}
	}, [pageDataQuery.data]);

	if (pageDataQuery.isLoading) {
		return (
			<PageLoading/>
		)

	} else if (pageDataQuery.error as unknown as ApiRequestFailed) {
		return <PageDataErrorHandler error={pageDataQuery.error as unknown as ApiRequestFailed}/>

	} else if (pageData) {

	} else {
		// Ideally it should not reach here.
		return <></>
	}

	function getRenewalTypeDisplayName(renewalTypeID: string) {
		if (pageData && renewalTypeID) {
			return pageData.all_renewal_types.filter(value => value.id === renewalTypeID)[0].display_name;
		} else {
			return null;
		}
	}

	function getPlanDisplayName(planBackendId: string) {
		if (pageData && planBackendId) {
			return pageData.all_plans.filter(value => value.id === planBackendId)[0].display_name;
		} else {
			return null;
		}
	}

	return (
		<div className="w-full flex flex-col">
			<h1 className="font-helvetica-neue-bold text-2xl">
				Manage Subscription for <span className="text-primary">{pageData.user_email}</span> ({pageData.username})
			</h1>
			{/* -------------------------- CURRENT SUBSCRIPTION DETAIL CARDS -------------------------- */}
			<div className="w-full grid grid-cols-4 gap-6 mt-6">
				{/*  Plan Name */}
				<Card>
					<CardHeader>
						<p className={"text-lg font-bold"}>Current Plan</p>
						<p className={"text-md mt-2"}>{pageData.user_current_plan.display_name}</p>
					</CardHeader>
				</Card>
				{/*  Renewal Type */}
				<Card>
					<CardHeader>
						<p className={"text-lg font-bold"}>Renewal Type</p>
						<p className={"text-md mt-2"}>
							{getRenewalTypeDisplayName(pageData.user_current_plan.renewal_type_id)}
						</p>
					</CardHeader>
				</Card>
				{/*  Purchase Date */}
				<Card>
					<CardHeader>
						<p className={"text-lg font-bold"}>Subscribed On</p>
						<p className={"text-md mt-2"}>
							{pageData.user_current_plan.purchased_on}
						</p>
					</CardHeader>
				</Card>
				{/*  Renewal Date */}
				<Card>
					<CardHeader>
						<p className={"text-lg font-bold"}>Next Renewal Date</p>
						<p className={"text-md mt-2"}>
							{pageData.user_current_plan.next_renewal_date}
						</p>
					</CardHeader>
				</Card>
			</div>

			<hr className={"my-8 w-full"}/>

			{/* -------------------------- SUBCRIPTION UPDATE CONTROLS -------------------------- */}
			<div className={"flex flex-col"}>
				<div className={"flex flex-col"}>
					<p className={"text-xl font-helvetica-neue-bold"}>Change Active Subscription:</p>
					<p className={"mt-2"}>
						NOTE: To cancel a paid plan, move the user to FREE plan. It's available under monthly renewal.
					</p>
					<RadioGroup defaultValue="comfortable"
											className={"mt-4 space-y-2"}
											value={renewalType}
											onValueChange={value => setRenewalType(value)}>
						{pageData.all_renewal_types.map(renewal_type => (
							<div className="flex items-center space-x-3" key={renewal_type.id}>
								<RadioGroupItem value={renewal_type.id} id={`radio-${renewal_type.id}`}/>
								<Label htmlFor={`radio-${renewal_type.id}`}>
									{renewal_type.display_name} Plans
								</Label>
							</div>
						))}
					</RadioGroup>
					<Select value={newPlan} onValueChange={setNewPlan}>
						<SelectTrigger className="w-[200px] mt-4">
							<SelectValue placeholder="Select new plan"/>
						</SelectTrigger>
						<SelectContent>
							<SelectGroup>
								<SelectLabel>{getRenewalTypeDisplayName(renewalType)} Plans</SelectLabel>
								{pageData.all_plans
									.filter(value => value.renewal_type_id === renewalType)
									.map(plan => (
										<SelectItem value={plan.id} key={plan.id}>{plan.display_name}</SelectItem>
									))}
							</SelectGroup>
						</SelectContent>
					</Select>
					<ChangePlanButton userId={userID!}
														userEmailId={pageData.user_email}
														newPlanId={newPlan}
														newPlanName={getPlanDisplayName(newPlan) || "[error]"}
														renewalTypeId={renewalType}
														renewalTypeName={getRenewalTypeDisplayName(renewalType) || "[error]"}
														disabled={!(renewalType && newPlan)}/>
				</div>
			</div>
		</div>
	)
}

function ChangePlanButton(props: {
	userId: string,
	userEmailId: string,
	newPlanId: string,
	newPlanName: string,
	renewalTypeId: string,
	renewalTypeName: string,
	disabled: boolean,
}) {
	const navigate = useNavigate();
	const {showToast} = useOutletContext<BaseOutletContext>();

	// Mutation to change current plan.
	const changePlanMutation = useMutation({
		mutationKey: ["changePlanMutation"],
		mutationFn: () => authenticateAndPostData("/api/admin/user-subscription/", {
			"user_id": props.userId,
			"new_plan_id": props.newPlanId,
			"renewal_type_id": props.renewalTypeId,
		}),
		gcTime: 0,
		retry: retryFn,
		onSuccess: () => {
			// Refresh page on success.
			navigate(0);
		},
		onError: (error: ApiRequestFailed) => {
			console.error(error);
			showToast(
				"Error",
				error.data.message,
				"destructive"
			);
		}
	});

	// ========================================================================
	// --------------------------- MAIN RENDER CODE ---------------------------
	// ========================================================================

	return (
		<Dialog>
			<DialogTrigger asChild>
				<Button className={"w-[200px] mt-8"} disabled={props.disabled}>
					<CreditCard className={"w-5 h-5 mr-2"}/>Change Active Plan
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-[425px]">
				<DialogHeader>
					<DialogTitle>Change Plan Confirmation</DialogTitle>
					<DialogDescription>
						User <span className={"font-bold text-primary"}>{props.userEmailId}</span> will be
						moved to <span className={"font-bold text-primary"}>{props.newPlanName}</span> Plan
						with <span className={"font-bold text-primary"}>{props.renewalTypeName}</span> renewal period.
						Their usage time for all affected tools will be reset back to 0.
						<br/><br/>
						Confirm changes?
					</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<DialogClose asChild>
						<Button variant={"secondary"} size={"sm"}>Cancel</Button>
					</DialogClose>
					<Button size={"sm"} disabled={changePlanMutation.isPending} onClick={() => changePlanMutation.mutate()}>
						Confirm
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
